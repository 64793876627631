<template>
  <MiscellaneousOperatingSystem dialog-name="Operating Systems" />
</template>
<script>
import MiscellaneousOperatingSystem from '@/views/app/miscellaneous/OperatingSystem'

export default {
  name: 'OperatingSystemWrapper',
  components: {
    MiscellaneousOperatingSystem
  }
}
</script>
